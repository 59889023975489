<template>
  <section
    class="app-ecommerce-details"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="product === undefined"
    >
      <h4 class="alert-heading">
        Error fetching Project data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'e-project-list'}"
        >
          Project List
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card
      v-if="product"
      no-body
    >
      <b-card-body>
        <b-row
          class="my-2"
          rowspan
        >
          <b-col
            cols="6"
            md="5"
            class="mb-2 mb-md-0"
          >
            <b-button
              variant="outline-secondary"
              class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="cancel()"
            >
              <feather-icon
                icon="ChevronsLeftIcon"
                class="mr-50"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          class="my-2"
          rowspan
        >
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="mb-2 mb-md-0"
          >
            <!-- Product Name -->
            <h4>{{ product.name }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <span>by</span>
              <b-link class="company-name">
                {{ product.brand }}
              </b-link>
            </b-card-text>
            <swiper
              class="swiper-responsive-breakpoints"
              :options="swiperOptions"
            >
              <swiper-slide>
                <b-link>
                  <div class="item-heading">
                    <h5 class="text-truncate mb-0">
                      Illustration
                    </h5>
                  </div>
                  <div class="img-container">
                    <b-img
                      :src="product.imageName"
                      :alt="`Image of ${product.name}`"
                      class="product-img"
                      style="width:90%"
                    />
                  </div>
                </b-link>
              </swiper-slide>
              <swiper-slide>
                <b-link>
                  <div class="item-heading">
                    <h5 class="text-truncate mb-0">
                      Video
                    </h5>
                  </div>
                  <div class="img-container">
                    <b-embed
                      type="iframe"
                      aspect="16by9"
                      :src="product.videoUrl"
                      allowfullscreen
                    />
                  </div>
                </b-link>
              </swiper-slide>

              <!-- Add Arrows -->
              <div
                slot="button-next"
                class="swiper-button-next"
              />
              <div
                slot="button-prev"
                class="swiper-button-prev"
              />
            </swiper>
          </b-col>
          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="7"
          >
            <b-tabs
              v-model="tabIndex"
              style="top:-90px"
            >
              <b-tab active>
                <template #title>
                  <feather-icon icon="HomeIcon" />
                  <span>Description</span>
                </template>
                <b-card-text>
                  <!-- Project Description -->
                  <b-card-text>{{ product.description }}</b-card-text>
                  <!-- <b-card-text class="mt-2">
                    This seed source from the e-Groweed.com cannaverse is the first of all.
                    Seed sources are projects in alliance with e-Groweed.com that participate in
                    both the traditional model of plant sponsorship and the digital cannaverso.
                    <br><br>
                    The seeds are NFT digital assets which represent a certificate of ownership with
                    the ability to demonstrate their origin and originality.
                    <br>
                    Don't miss out on your NFT genetics from the e-Groweed.com cannaverse and start farming!
                    <br>
                  </b-card-text> -->
                  <!-- Project Meta [profit] -->
                  <ul class="product-features list-unstyled">
                    <li>
                      <feather-icon icon="ClockIcon" />
                      <span>You can instant trade in the Cannaverse</span></li>
                    <li>
                      <feather-icon icon="DollarSignIcon" />
                      <span>Possible earnings</span>
                    </li>
                  </ul>

                  <hr>
                  <div class="d-flex flex-column flex-sm-row pt-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                      @click="tabIndex = 1"
                    >
                      <feather-icon
                        icon="ShoppingCartIcon"
                        class="mr-50"
                      />
                      <span>Get Now!</span>
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                      @click="cancel()"
                    >
                      <feather-icon
                        icon="ChevronsLeftIcon"
                        class="mr-50"
                      />
                      <span>Cancel</span>
                    </b-button>
                    <b-dropdown
                      variant="outline-secondary"
                      no-caret
                      toggle-class="btn-icon"
                      class="btn-share"
                      right
                    >
                      <template #button-content>
                        <feather-icon icon="Share2Icon" />
                      </template>
                      <b-dropdown-item
                        v-for="icon in [ 'TwitterIcon', ]"
                        :key="icon"
                      >
                        <feather-icon :icon="icon" />
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon icon="ShoppingCartIcon" />
                  <span>NFT Market</span>
                </template>
                <b-card-text style="background-color: #181e304a; margin: 5px; border-radius: 8px 8px 8px 8px;">
                  <e-project-shop
                    :geneticsprop="product.toSell"
                    :greenhouseid="productId"
                    style="margin:10px"
                    class="mt-2"
                  />
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
      <!-- <e-project-product-details-item-features />-->
      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <!--<e-project-product-details-related-projects />-->
    </b-card>
  </section>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BCard, BTabs, BTab, BEmbed, BImg, BCardBody, BRow, BCol, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Ripple from 'vue-ripple-directive'
import EProjectShop from '../e-project-nft-store/EProjectShop.vue'
// import EProjectProductDetailsItemFeatures from './EProjectProductDetailsItemFeatures.vue'
// import EProjectProductDetailsRelatedProjects from './EProjectProductDetailsRelatedProducts.vue'
import { useEcommerceUi } from '../useEProject'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BEmbed,
    BImg,
    BTabs,
    BTab,

    Swiper,
    SwiperSlide,

    // SFC
    // EProjectProductDetailsItemFeatures,
    // EProjectProductDetailsRelatedProjects,
    EProjectShop,
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 1,
      spaceBetween: 50,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const product = ref(null)
    // Remote Data
    // Get product  id from URL
    const { route } = useRouter()
    const productSlug = route.value.params.slug
    const productId = productSlug.substring(productSlug.lastIndexOf('-') + 1)
    const fetchProduct = () => {
      store.dispatch('projects-ecommerce/fetchGreenHouse', { productId })
        .then(response => {
          product.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            product.value = undefined
          }
        })
    }

    // UI
    const selectedColor = ref(null)
    fetchProduct()
    // obtener el fee del gas
    // Calcular precio de USD a ETH
    return {
      // Fetched Product
      product,
      productId,

      // UI
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
      swiperOptions,
      tabIndex: 0,
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
  @import "~@core/scss/base/pages/app-ecommerce-details.scss";
  @import '@core/scss/vue/libs/swiper.scss';
  @import '~swiper/css/swiper.css';
  .modal-header .close {
    display:none;
  }
</style>
