<template>
  <b-card
    img-alt="User Profile"
    img-top
    class="card-profile"
    style="top:30px"
  >
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          size="114"
          variant="light"
          :src="nftimage"
        />
      </div>
    </div>
    <h3>{{ `${this.$props.name}` }}</h3>
    <b-badge
      style="white-space: break-spaces; font-size: 15px"
      class="profile-badge"
      variant="light-primary"
      v-html="this.$props.description"
    />
    <!-- <hr class="mb-2"> -->
    <!-- follower projects rank -->
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h6 class="text-muted font-weight-bolder">
          Purchase price
        </h6>
        <h5 class="mb-0">
          {{ `${this.$props.value}` }} BCA
        </h5>
      </div>
      <div>
        <h6
          class="text-muted font-weight-bolder"
          style="color:green !important"
        >
          Possible Profixs
        </h6>
      </div>
    </div>
    <hr class="mb-1">
    <b-badge
      v-show="$props.showtransactiondata.hash.length==0"
      class="profile-badge"
      variant="light-primary"
    >
      Attributes
    </b-badge>
    <div
      v-show="$props.showtransactiondata.hash.length==0"
      style="text-align: left; left:5px"
    >
      <ul
        v-for="property in properties"
        :key="property.id"
        class="product-features list-unstyled"
      >
        <li>
          <feather-icon
            style="max-width:14px"
            icon="CrosshairIcon"
          />&nbsp;
          <span>{{ property.value }}</span>
        </li>
      </ul>
    </div>
    <b-badge
      v-show="$props.showtransactiondata.hash.length>0"
      class="profile-badge"
      variant="light-primary"
    >
      Transaction Detail
    </b-badge>
    <div
      v-show="$props.showtransactiondata.hash.length>0"
      style="text-align: left;"
    >
      <ul class="product-features list-unstyled">
        <li class="d-flex">
          <span class="text-success">Hash</span>&nbsp;
          <feather-icon icon="HashIcon" />&nbsp;
          <b-button
            variant="light-primary"
            style="color:#5d57bd"
            @click="showHashBlockExplorer($props.showtransactiondata.hash)"
          >
            {{ $props.showtransactiondata.hash.substr(0, $props.showtransactiondata.hash.length-40) }}
          </b-button>
        </li>
        <li>
          <span class="text-success">Price ETH:</span>&nbsp;
          <span>{{ $props.showtransactiondata.ethPrice }}</span><br><br>
        </li>
        <li>
          <span class="text-success">Message:</span>&nbsp;
          <span>{{ $props.showtransactiondata.msg }}</span>
        </li>
      </ul>
    </div>
    <!--/ follower projects rank -->
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BBadge, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BButton,
  },
  props: [
    'properties',
    'nftimagename',
    'name',
    'shares',
    'value',
    'description',
    'showtransactiondata',
  ],
  data() {
    return {
      nftimage: this.$props.nftimagename,
      minMarketPrice: this.numberInFormat(parseFloat(this.$props.value.replace(/,/gi, '')) + (parseFloat(this.$props.value.replace(/,/gi, '')) * 0.08), 2, '.', ','),
    }
  },
  methods: {
    showHashBlockExplorer(hash) {
      if (hash === '') return

      window.open(`https://explorer.testnet.rsk.co/tx/${hash}`, '_blank')
    },
    numberInFormat: (inNumber, decimales, unitDecial, unitThousant) => {
      let number
      if (inNumber != null) {
        number = parseFloat(inNumber.toString().replace(/,/gi, ''))
      } else {
        number = Number.NaN
      }
      if (Number.isNaN(number)) {
        return ''
      }

      if (decimales !== undefined) {
        // Redondeamos
        number = number.toFixed(decimales)
      }

      // Convertimos el punto en unitDecial
      number = number.toString().replace('.', unitDecial !== undefined ? unitDecial : ',')

      if (unitThousant) {
        // Añadimos los separadores de miles
        const miles = new RegExp('(-?[0-9]+)([0-9]{3})')
        while (miles.test(number)) {
          number = number.replace(miles, `$1${unitThousant}$2`)
        }
      }
      return number
    },
  },
}
</script>
